import axios from 'axios'
import axiosRetry from 'axios-retry'
import to from 'await-to-js'
import {
  NETWORK_TIMEOUT,
  RETRY_COUNT,
  SEARCH_FRONT_SERVER_ENDPOINT,
} from '../../_app/constants/common'
import { captureException } from '../../_app/utils/captureException'
import type { AppType, UserType } from '../../_app/context/FoundationProvider'

import type { SearchReferrerType } from '../../referrer/types'
import daangnAxiosInterceptors from '../../plantae/daangnAxiosInterceptors'
import {
  plantaeAuthPlugin,
  plantaeCommonHeadersPlugin,
  plantaeKarrotSessionIdPlugin,
  plantaeRequestIdPlugin,
  plantaeSearchOriginPlugin,
  plantaeUserAgentPlugin,
} from '../../plantae/plugins'
import { SEARCH_EXPERIMENT_RESPONSE_KEY } from '../../experiment/constants/experiment'
import { getExperimentPayloadFromHeader } from '../../experiment/utils/getExperimentPayloadFromHeader'
import { convertCollectionMetaToConnection } from '../../_app/utils/convertCollectionToConnection'
import {
  PlaceApi,
  type ApiV1PlaceSearchPostRequest,
} from '../../__codegen__/__openapi__/search-front-server'
import { addXSearchHeaders } from '../../_app/utils/addCustomHeader'

interface PlacesV4SearchRequestParamsType extends ApiV1PlaceSearchPostRequest {
  referrer: SearchReferrerType
}

let serviceCache: ServiceSearchPlaceType | null = null

export type ServiceSearchPlaceType = ReturnType<typeof ServiceSearchPlace>

export function getServiceSearchPlace({
  user,
  app,
}: {
  user: UserType
  app: AppType
}) {
  if (serviceCache) {
    return serviceCache
  }

  return (serviceCache = ServiceSearchPlace({
    baseUrl: SEARCH_FRONT_SERVER_ENDPOINT,
    user,
    app,
  }))
}

const ServiceSearchPlace = ({
  baseUrl,
  user,
  app,
}: {
  baseUrl: string
  user: UserType
  app: AppType
}) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    headers: headers,
    timeout: NETWORK_TIMEOUT,
  })
  daangnAxiosInterceptors({
    client: axiosInstance,
    plugins: [
      plantaeAuthPlugin({ fallbackAuthToken: user.authToken }),
      plantaeRequestIdPlugin(),
      plantaeKarrotSessionIdPlugin({ app }),
      plantaeUserAgentPlugin({ userAgent: app.userAgent }),
      plantaeCommonHeadersPlugin(),
      plantaeSearchOriginPlugin(),
    ],
  })

  axiosRetry(axiosInstance, {
    retries: RETRY_COUNT,
    retryDelay: () => 0,
    retryCondition: () => true,
    shouldResetTimeout: true,
  })

  const client = new PlaceApi(undefined, baseUrl, axiosInstance)

  return {
    /**
     * 장소(poi/bizaccount) 목록 조회
     */
    getPlaces: async ({
      query,
      regionId,
      pageSize,
      pageToken,
      sort,
      useLocation,
      location,
      useMap,
      mapLocation,
      referrer,
    }: PlacesV4SearchRequestParamsType) => {
      const [error, response] = await to(
        client.apiV1PlaceSearchPost(
          {
            apiV1PlaceSearchPostRequest: {
              regionId: regionId,
              pageSize: pageSize,
              query: query,
              pageToken: pageToken,
              sort: sort,
              useLocation: useLocation,
              location: location,
              useMap: useMap,
              mapLocation: mapLocation,
            },
          },
          {
            headers: addXSearchHeaders(referrer),
          }
        )
      )

      if (error) {
        captureException(error)
        return null
      }

      if (!response?.data) {
        return null
      }

      if ('error' in response?.data) {
        return {
          connection: convertCollectionMetaToConnection([]),
          [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
            response.headers
          ),
          error: response.data.details,
        }
      }

      const connection = convertCollectionMetaToConnection(
        response.data.documents
      )
      return {
        connection,
        [SEARCH_EXPERIMENT_RESPONSE_KEY]: getExperimentPayloadFromHeader(
          response.headers
        ),
      }
    },
  }
}
